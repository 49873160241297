import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactHeap from 'reactjs-heap';
import axios from 'axios';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppConfig } from './util/appConfig';

async function loadConfigAndStart() {
  const response = await axios.get(`${process.env.PUBLIC_URL}/config.json`);
  const config = response.data as AppConfig;
  ReactHeap.initialize(config.heapAppId);

  ReactDOM.render(<App config={config} />, document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}

loadConfigAndStart();
