import React from 'react';
import {
  BrowserRouter as Router, Route, Redirect,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AppConfig, AppConfigContext } from './util/appConfig';

type AppProps = {
  config: AppConfig;
};

const helmetContext = {};

const App: React.FC<AppProps> = ({ config }) => (
  <HelmetProvider context={helmetContext}>
    <AppConfigContext.Provider value={config}>
      <Router>
        <Route
          exact
          strict
          path="/(.+)"
          component={() => (
            <Redirect to={{
              pathname: '',
            }}
            />
          )}
        />
        <Route path="/" component={() => { window.location.href = 'https://my.loanbuilder.com/us/login'; return null; }} />
      </Router>
    </AppConfigContext.Provider>
  </HelmetProvider>
);

export default App;
