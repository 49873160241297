import React from 'react';

export interface AppConfig {
  customerPortalUrl: string;
  customerSupportPhone: string;
  heapAppId: string;
}

export const AppConfigContext = React.createContext<AppConfig>({
  customerPortalUrl: 'https://my.dev.loanbuider.com/',
  customerSupportPhone: '(888) 923-0007',
  heapAppId: '3027341346',
});
